// @import "../node_modules/@angular/material/prebuilt-themes/deeppurple-amber.css";

@import "~@ng-select/ng-select/themes/default.theme.css";
@import "~@angular/material/prebuilt-themes/indigo-pink.css";
@import "~ng-pick-datetime/assets/style/picker.min.css";
@import 'ngx-toastr/toastr';
// @use '@angular/material' as mat;
// @import '@angular/material/theming';
@import 'styles/theme';

@tailwind base;
@tailwind components;
@tailwind utilities;


// $my-custom-level: mat.define-typography-level(
//   $font-family: Poppins,
//   $letter-spacing: normal,
// );
